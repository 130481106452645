import React, { Fragment } from 'react';
import { api } from '../Modul';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            NamaProfile: "",
            NamaUser: "Heru Prasetia"
        };
    }

    async componentDidMount() {
        let sql = await api("controler/profile_api", { act: 'data profile' });
        if (sql.status == "sukses") this.setState({ NamaProfile: sql.data.Nama });
    }

    render() {
        return (
            <div className='d-flex justify-content-center align-items-center' style={{ height: "90vh" }}>
                <h5>Selamat datang di sistem akademi {this.state.NamaProfile}</h5>
            </div>
        )
    }

}

export default Dashboard;
