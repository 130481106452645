import React, { Fragment } from 'react';
import { saiki, pesan, api, submitForm, openModal } from '../Modul';
import { Chart, Series, ArgumentAxis, CommonSeriesSettings, Export, Legend, Margin } from 'devextreme-react/chart';
import PieChart, { Tooltip, Format, Label, Connector } from 'devextreme-react/pie-chart';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            DataPengujung: [{
                country: 'China',
                Guru: 233866959,
                Siswa: 1170914102,
                Staff: 171774113,
            }, {
                country: 'India',
                Guru: 373419115,
                Siswa: 882520945,
                Staff: 76063757,
            }, {
                country: 'United States',
                Guru: 60554755,
                Siswa: 213172625,
                Staff: 54835293,
            }, {
                country: 'Indonesia',
                Guru: 65715705,
                Siswa: 177014815,
                Staff: 18053690,
            }, {
                country: 'Brazil',
                Guru: 45278034,
                Siswa: 144391494,
                Staff: 17190842,
            }, {
                country: 'Russia',
                Guru: 24465156,
                Siswa: 96123777,
                Staff: 20412243,
            }],
            DataStatusBuku: [
                {
                    region: 'Tersedia',
                    val: 300,
                },
                {
                    region: 'Di Pinjam',
                    val: 100,
                },
                {
                    region: 'Rusak / Hilang',
                    val: 20,
                },
            ],
            DataPeminjam: [
                {
                    state: 'Agama',
                    year2020: 803,
                    year2021: 823,
                    year2022: 863,
                },
                {
                    state: 'Sains',
                    year2020: 316,
                    year2021: 332,
                    year2022: 332,
                },
                {
                    state: 'Sastra',
                    year2020: 452,
                    year2021: 459,
                    year2022: 470,
                },
                {
                    state: 'Sosial',
                    year2020: 621,
                    year2021: 642,
                    year2022: 675,
                },
                {
                    state: 'Lain-lain',
                    year2020: 290,
                    year2021: 294,
                    year2022: 301,
                },
            ],
            DataKategoriBuku: [
                {
                    region: 'Agama',
                    val: 300,
                },
                {
                    region: 'Sains',
                    val: 10,
                },
                {
                    region: 'Sastra',
                    val: 120,
                },
                {
                    region: 'Sosial',
                    val: 100,
                },
                {
                    region: 'Lain-lain',
                    val: 200,
                },
            ],
            Akses: true
        };
    }

    async componentDidMount() {
        // this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("admin_api", { act: "data", q: this.state.q });
        if (sql.status == "sukses") {
            this.setState({ Data: sql.data });
        } else {
            this.setState({ Akses: false });
        }
    }

    customizeTooltip(arg) {
        return {
            text: `${arg.valueText} - ${(arg.percent * 100).toFixed(2)}%`,
        };
    }

    onPointClick(e) {
        e.target.select();
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        {this.state.Akses == true ?
                            <Fragment>
                                <div className="col-md-9 main-title">Overview Perpus</div>
                                <p></p>
                                <div className="row">
                                    <div className="col-6">
                                        <div className='card shadow'>
                                            <div className='card-header'>
                                                <h4>Daftar Pengunjung</h4>
                                            </div>
                                            <div className='card-body'>
                                                <Chart
                                                    palette="Harmony Light"
                                                    title="Data Pengunjung"
                                                    dataSource={this.state.DataPengujung}
                                                >
                                                    <CommonSeriesSettings
                                                        argumentField="country"
                                                        type="stackedarea"
                                                    />
                                                    <Series valueField="Guru" name="Guru"></Series>
                                                    <Series valueField="Siswa" name="Siswa"></Series>
                                                    <Series valueField="Staff" name="Staff"></Series>
                                                    <Margin bottom={20} />
                                                    <ArgumentAxis valueMarginsEnabled={false} />
                                                    <Legend
                                                        verticalAlignment="bottom"
                                                        horizontalAlignment="center"
                                                    />
                                                    <Export enabled={true} />
                                                </Chart>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className='card shadow'>
                                            <div className='card-header'>
                                                <h4>Status Buku</h4>
                                            </div>
                                            <div className='card-body'>
                                                <PieChart
                                                    id="pie"
                                                    type="doughnut"
                                                    title="Laporan Status Buku"
                                                    palette="Soft Pastel"
                                                    dataSource={this.state.DataStatusBuku}
                                                >
                                                    <Series argumentField="region">
                                                        <Label
                                                            visible={true}
                                                            format="decimal"
                                                        >
                                                            <Connector visible={true} />
                                                        </Label>
                                                    </Series>
                                                    <Export enabled={true} />
                                                    <Legend
                                                        margin={0}
                                                        horizontalAlignment="right"
                                                        verticalAlignment="top"
                                                    />
                                                    <Tooltip
                                                        enabled={true}
                                                        customizeTooltip={this.customizeTooltip}
                                                    >
                                                        <Format type="decimal" />
                                                    </Tooltip>
                                                </PieChart>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <p></p>
                                <div className="row">
                                    <div className="col-6">
                                        <div className='card shadow'>
                                            <div className='card-header'>
                                                <h4>Daftar Peminjam</h4>
                                            </div>
                                            <div className='card-body'>
                                                <Chart id="chart"
                                                    title="Laporan Peminjam Tahunan"
                                                    dataSource={this.state.DataPeminjam}
                                                    onPointClick={this.onPointClick}
                                                >
                                                    <CommonSeriesSettings
                                                        argumentField="state"
                                                        type="bar"
                                                        hoverMode="allArgumentPoints"
                                                        selectionMode="allArgumentPoints"
                                                    >
                                                        <Label visible={true}>
                                                            <Format type="fixedPoint" precision={0} />
                                                        </Label>
                                                    </CommonSeriesSettings>
                                                    <Series
                                                        argumentField="state"
                                                        valueField="year2022"
                                                        name="2021"
                                                    />
                                                    <Series
                                                        valueField="year2021"
                                                        name="2022"
                                                    />
                                                    <Series
                                                        valueField="year2020"
                                                        name="2023"
                                                    />
                                                    <Legend verticalAlignment="bottom" horizontalAlignment="center"></Legend>
                                                    <Export enabled={true} />
                                                </Chart>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className='card shadow'>
                                            <div className='card-header'>
                                                <h4>Kategori Buku</h4>
                                            </div>
                                            <div className='card-body'>
                                                <PieChart
                                                    id="pie"
                                                    type="doughnut"
                                                    title="Laporan Status Buku"
                                                    palette="Soft Pastel"
                                                    dataSource={this.state.DataKategoriBuku}
                                                >
                                                    <Series argumentField="region">
                                                        <Label
                                                            visible={true}
                                                            format="decimal"
                                                        >
                                                            <Connector visible={true} />
                                                        </Label>
                                                    </Series>
                                                    <Export enabled={true} />
                                                    <Legend
                                                        margin={0}
                                                        horizontalAlignment="right"
                                                        verticalAlignment="top"
                                                    />
                                                    <Tooltip
                                                        enabled={true}
                                                        customizeTooltip={this.customizeTooltip}
                                                    >
                                                        <Format type="decimal" />
                                                    </Tooltip>
                                                </PieChart>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Fragment> : <img src={require("../accessdenied.jpeg")} width="100%" className='shadow-lg' />
                        }
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
