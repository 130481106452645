import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { api } from '../Modul';
import RendTables from '../component/RendTable';

const Main = () => {
    const [Data, setData] = useState([]);
    const [Field, setField] = useState([]);
    const [Title, setTitle] = useState("Selamat Datang");
    const location = useLocation();

    useEffect(() => {
        const handleMain = async () => {
            let Path = location.pathname.replaceAll('/', '');
            let sql = await api("controler/profile_api", { act: "data menu", Path: Path }, true);

            if (sql.status === "sukses") {
                setField(sql.data.TableField ? JSON.parse(sql.data.TableField) : []);
                setTitle(sql.data.Nama);
            }
        };

        handleMain();
    }, [location.pathname]);

    useEffect(() => {
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme === "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark");
            }
        }
    }, []);

    return (
        <div>
            <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
            <div className="main-body">
                <div className="div-content mt-1">
                    <div className="row">
                        <div className="col-md-9 main-title"><span className='badge bg-danger'>Preview</span> {Title}</div>
                        <div className="col-md-3 d-flex gap-1">
                            <div className="input-group gap-2">
                                <input
                                    type="search"
                                    className="form-control form-control-sm"
                                    placeholder="Cari Data"
                                />
                                <button className="btn btn-default">
                                    <i className="fas fa-search"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                    <RendTables
                        tbody={Data}
                        thead={Field}
                        opt={[
                            { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                            { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                        ]}
                    />
                </div>
            </div>
        </div>
    );
};

export default Main;
