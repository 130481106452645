import React, { Fragment } from 'react';
import { api, submitForm, openModal, exportData, importData, checkDateFormat } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            q: "",
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/perpus_api", { act: "data master kategori buku", q: this.state.q });
        if (sql.status == "sukses") this.setState({ Data: sql.data, Field: sql.TableField });
    }

    async handleModalForm(Detail = {}) {
        let sql = await api("controler/perpus_api", { act: "detail master kategori buku", ID: Detail.ID });
        if (sql.status == "sukses") this.setState({ Detail: Detail });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        <div className="row">
                            <div className="col-md-8 main-title">Master Kategori Buku</div>
                            <div className="col-md-4 d-flex gap-1">
                                <div className="input-group">
                                    <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                    <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Buku' />
                                    <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                </div>
                            </div>
                        </div>
                        <RendTables
                            tbody={this.state.Data}
                            thead={this.state.Field}
                            opt={[
                                { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                            ]} />
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/perpus_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value={this.state.Detail.ID == undefined ? "tambah master kategori buku" : "edit master kategori buku"} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah kategori Buku" : "Edit kategori Buku"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Kode</label>
                                                <input type="text" className='form-control' id="edtKode" name="Kode" value={this.state.Detail.Kode || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Kode = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Kode</div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className='form-group'>
                                                <label>Nama</label>
                                                <input type="text" className='form-control' id="edtNama" name="Nama" value={this.state.Detail.Nama || ""} onChange={(e) => {
                                                    let data = this.state.Detail;
                                                    data.Nama = e.target.value;
                                                    this.setState({ Detail: data });
                                                }} required />
                                                <div className='invalid-feedback'>Silahkan masukan Nama</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='form-group'>
                                        <label>Keterangan</label>
                                        <textarea className='form-control' id="edtKeterangan" name="Keterangan" value={this.state.Detail.Keterangan || ""} onChange={(e) => {
                                            let data = this.state.Detail;
                                            data.Keterangan = e.target.value;
                                            this.setState({ Detail: data });
                                        }} />
                                        <div className='invalid-feedback'>Silahkan masukan Keterangan</div>
                                    </div>

                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormMenu'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/perpus_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus master kategori buku" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus kategori Buku</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus kategori buku ini !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusMenu'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
