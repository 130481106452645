import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import { api, pesan, Token, host, isJson, openModal, submitForm } from './Modul';
import { requestForToken } from './firebase';
import Logo from './assets/img/logo.png';
import LogoPanjang from './assets/img/logo.png';
import Profile from './assets/img/profile.png';
import BG2 from './assets/img/Designer.jpeg';
import './App.css';
import './Login.css';

// PAGE
import Dashboard from './page/Dashboard';
import OverviewPerpus from './page/OverviewPerpus';
import Menu from './page/MasterMenu';
import Admin from './page/MasterAdmin';
import MasterMurid from './page/MasterMurid';
import MasterKelas from './page/MasterKelas';
import MasterGuru from './page/MasterGuru';
import MasterAnggotaPerpus from './page/MasterAnggotaPerpus';
import MasterMapel from './page/MasterMapel';
// PERPUS
import MasterKategoriBuku from './page/MasterKategoriBuku';
import MasterBuku from './page/MasterBuku';
import TransPeminjamanBuku from './page/TransPeminjamanBuku';
// END PERPUS
import Home from './page/Home';

function App() {
  class Main extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        Menu: [],
        Group: [],
        CurePage: [],
        NamaUser: "Heru Prasetia",
        NamaProfile: "",
        divSetting: [],
        detailSetting: [],
        clsLoading: "fas fa-save",
        btnSubmit: false,
        iconTheme: "fas fa-moon",
        show: false,
        notification: { title: '', body: '' },
        tokenFound: false,
        Password: "",
        RePassword: ""
      };
    }

    async componentDidMount() {
      let sql = await api("controler/mastermenu_api", { act: "data menu" });
      if (sql.status == "sukses") {
        let TokenFirebase = localStorage.getItem("TokenFirebase");
        if (!TokenFirebase) requestForToken(this.setTokenFound);
        let Menu = [], Group = sql.group, Akses = JSON.parse(sql.data.Akses);
        for (let menu of sql.menu) {
          for (let dd of Akses) {
            if (dd.ID == menu.ID) Menu.push(menu);
          }
        }
        for (let gg in Group) {
          for (let dd of Menu) {
            if (Group[gg].ID == dd.GroupID) Group[gg].Active = true;
          }
        }
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
          document.body.classList.add('dark-theme');
          let cls = document.getElementsByClassName("table");
          for (let i = 0; i < cls.length; i++) {
            cls[i].classList.add("table-dark");
          }
          let clsOffCanvas = document.getElementsByClassName("offcanvas");
          for (let i = 0; i < clsOffCanvas.length; i++) {
            clsOffCanvas[i].classList.add("text-bg-dark");
          }
          let clsCard = document.getElementsByClassName("card");
          for (let i = 0; i < clsCard.length; i++) {
            clsCard[i].classList.add("text-bg-dark");
          }
        }

        if (window.screen.width < 700) {
          document.getElementById("sidebar").style.display = "none";
          document.getElementById("sidebar").classList.toggle("show");
        }
        this.setState({ Menu: Menu, Group: Group, iconTheme: ColorTheme == "light" ? "fas fa-moon" : "fas fa-sun", NamaUser: sql.user.Nama, NamaProfile: sql.profile.Nama });
        // console.clear();
      } else {
        if (sql.pesan == "Sesi tidak ditemukan") this.handleLogout();
      }
    }

    setTokenFound = async (tokenFound, token) => {
      if (tokenFound) {
        await api("datasiswa", { act: "set token", TokenFireBase: token });
        this.setState({ tokenFound });
        localStorage.setItem("TokenFirebase", token);
      }
    };

    handleColaps() {
      document.getElementById("sidebar").style.display = "block";
      document.getElementById("sidebar").classList.toggle("hide");
    }

    handlePilihMenu(Menu, Title) {
      let clsMenu = document.getElementsByClassName("menu");
      for (let i = 0; i < clsMenu.length; i++) clsMenu[i].classList.remove("active");
      document.getElementById('menu' + Menu).classList.add("active");
      document.title = Title;
    }

    handleLogout() {
      localStorage.clear();
      window.location.reload();
    }

    handleChangeTheme() {
      let ColorTheme = localStorage.getItem("ColorTheme") || "light";
      if (ColorTheme == "light") {
        localStorage.setItem("ColorTheme", "dark");
        this.setState({ iconTheme: "fas fa-sun" });
      } else {
        localStorage.setItem("ColorTheme", "light");
        this.setState({ iconTheme: "fas fa-moon" });
      }
      document.body.classList.toggle('dark-theme');
      let cls = document.getElementsByClassName("table");
      for (let i = 0; i < cls.length; i++) {
        cls[i].classList.toggle("table-dark")
      }
    }

    async handleCariMenu(val) {
      let __typingTimer;
      clearTimeout(__typingTimer);
      __typingTimer = setTimeout(async () => {
        let sql = await api("controler/mastermenu_api", { act: "data menu", q: val });
        if (sql.status == "sukses") {
          let Menu = [], Group = sql.group, Akses = JSON.parse(sql.data.Akses);
          for (let menu of sql.menu) {
            for (let dd of Akses) {
              if (dd.ID == menu.ID) Menu.push(menu);
            }
          }
          for (let gg in Group) {
            for (let dd of Menu) {
              if (Group[gg].ID == dd.GroupID) Group[gg].Active = true;
            }
          }
          this.setState({ Menu: Menu, Group: Group });
        }
      }, 500);
    }

    handleChangePwd() {
      openModal("modalGantiPassword");
    }

    render() {
      return (
        <Router>
          <div className="wrapper">
            <nav id="sidebar" className="sidebar">
              <div className="sidebar-header cursor d-flex gap-2 align-items-center" onClick={() => window.location.reload}>
                <img src={LogoPanjang} style={{ width: "50px" }} className="logo-panjang" />
                <img src={Logo} style={{ width: "50px", height: "50px" }} className="logo" />
                <h5 className='color-default'>{this.state.NamaProfile}</h5>
              </div>
              <div className="transbox">
                <input type="search" name="qMenu" id="qMenu" className="form-control form-control-sm" placeholder="Cari Menu . . ." onFocus={(e) => e.target.select()} autoComplete="off" onChange={(e) => this.handleCariMenu(e.target.value)} />
                <ul className="list-unstyled components" id="ulMenu">
                  {
                    this.state.Menu.map((li, i) => {
                      if (li.GroupID == "0") {
                        return (
                          <li className="menu" id={"menu" + li.Path} key={li.Path} onClick={() => this.handlePilihMenu(li.Path, li.Nama)}>
                            <Link to={`/${li.Path}`} className='d-flex align-items-center gap-2'>
                              <i className={li.Icon}></i> <span className="nama-menu">{li.Nama}</span>
                            </Link>
                          </li>
                        )
                      }
                    })
                  }
                  {
                    this.state.Group.map((ul, i) => {
                      if (ul.Active == true) {
                        return (
                          <li key={i}>
                            <a href={`#GroupMenu${ul.ID}`} data-bs-toggle="collapse" aria-expanded="false" className="dropdown-toggle d-flex align-items-center gap-2" key={ul.ID}>
                              <i className={ul.Icon}></i>
                              <span className="nama-menu">{ul.Nama}</span>
                            </a>
                            <ul className="collapse list-unstyled" id={`GroupMenu${ul.ID}`}>
                              {
                                this.state.Menu.map((li, ii) => {
                                  if (li.GroupID == ul.ID) {
                                    return (
                                      <li className="menu" id={"menu" + li.Path} key={li.Path} onClick={() => this.handlePilihMenu(li.Path, li.Nama)}>
                                        <Link to={`/${li.Path}`} className='d-flex align-items-center gap-2'>
                                          <i className={li.Icon}></i>
                                          {li.Nama}
                                        </Link>
                                      </li>)
                                  }
                                })
                              }
                            </ul>
                          </li>
                        )
                      }
                    })
                  }
                </ul>
              </div>
            </nav >
            <div id="content">
              <nav className="navbar">
                <div className="container-fluid d-flex nav-container">
                  <button type="button" id="sidebarCollapse" className="btn btn-sm nav-menu" onClick={() => this.handleColaps()}> <i className="fas fa-bars"></i> </button>
                  <div className="d-flex justify-content-end align-items-center nav-menu-items">
                    <button type="button" className="btn align-items-center nav-menu" style={{ position: "relative" }} onClick={() => this.handleChangeTheme()}>
                      <i className={this.state.iconTheme}></i>
                    </button>
                    <div className="dropdown ">
                      <button className="dropdown-toggle nav-profile" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                        <div className="nav-profile-photo">
                          <img src={Profile} />
                        </div>
                      </button>
                      <ul className="dropdown-menu dropdown-menu-end shadow-lg dropdown-menu-dark" style={{ minWidth: "14rem", fontSize: "0.9rem", maxHeight: "440px", overflowY: "auto" }}>
                        <li className="dropdown-item">
                          <a><b>Login Sebagai : </b></a>
                          <br></br>
                          <p className="mt-2 d-flex align-items-end gap-2">
                            <i className="fas fa-user"></i>
                            <span id='spanUserName'>{this.state.NamaUser}</span>
                          </p>
                        </li>
                        <li>
                          <hr className="dropdown-divider"></hr>
                        </li>
                        <li><span onClick={() => this.handleChangePwd()} className="dropdown-item d-flex justify-content-between">Ganti Password <i className="fas fa-unlock-alt"></i></span> </li>
                        <li><span onClick={() => this.handleLogout()} className="dropdown-item d-flex justify-content-between">Keluar <i className="fas fa-sign-out-alt"></i></span> </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </nav>
              <div style={{ height: "fit-content", border: "none" }} id="tampil">
                <Routes>
                  <Route path="/" element={<Dashboard page="Home" />} />
                  <Route path="/dashboard" element={<Dashboard page="Home" />} />
                  <Route path="/Admin" element={<Admin page="Admin" />} />
                  <Route path="/menu" element={<Menu page="Menu" />} />
                  <Route path="/mastermurid" element={<MasterMurid page="MasterMurid" />} />
                  <Route path="/masterkelas" element={<MasterKelas page="MasterKelas" />} />
                  <Route path="/mastermatapelajaran" element={<MasterMapel page="MasterMapel" />} />
                  <Route path="/masterguru" element={<MasterGuru page="MasterGuru" />} />
                  <Route path="/overviewperpus" element={<OverviewPerpus page="OverviewPerpus" />} />
                  <Route path="/masterkategoribuku" element={<MasterKategoriBuku page="MasterKategoriBuku" />} />
                  <Route path="/masterbuku" element={<MasterBuku page="MasterBuku" />} />
                  <Route path="/masteranggotaperpus" element={<MasterAnggotaPerpus page="MasterAnggotaPerpus" />} />
                  <Route path="/transpeminjamanbuku" element={<TransPeminjamanBuku page="TransPeminjamanBuku" />} />

                  <Route path="*" element={<Home />} />
                </Routes>
              </div>
              <div id="divPesan"></div>
            </div>
          </div>

          <div className="modal fade" id="modalGantiPassword" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
              <div className="modal-content">
                <form onSubmit={(e) => submitForm(e, { crud: 'model/profile_crud', fn: () => { window.location.reload() }, debug: true })} className='needs-validation' noValidate>
                  <input type='hidden' name="act" value="ganti password" />
                  <div className="modal-header">
                    <h1 className="modal-title fs-5">Ganti Password</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <div className="form-group">
                      <label>Password Baru</label>
                      <input type="password" className="form-control" name='Password' id="edtPassword" value={this.state.Password} onChange={(e) => this.setState({ Password: e.target.value })} placeholder='Silahkan masukan kata sandi' required />
                      <div className="invalid-feedback">Silahkan masukan password</div>
                    </div>
                    <div className="form-group">
                      <label>Ulangi Password</label>
                      <input type="password" className="form-control" name='RePassword' id="edtRePassword" value={this.state.RePassword} onChange={(e) => this.setState({ RePassword: e.target.value })} placeholder='Silahkan masukan kata sandi' required />
                      <div className="invalid-feedback">Silahkan masukan password lagi</div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalChangePws'>Batal</button>
                    <button type="submit" className="btn btn-danger"><i className="fas fa-save"></i> Ganti</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Router >
      )
    }
  }

  class Login extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        Email: "",
        Pwd: "",
        clsLogin: "",
        btnLogin: false
      };
    }

    async handleLogin(e) {
      e.preventDefault();
      e.stopPropagation();
      if (e.target.checkValidity()) {
        this.setState({ btnLogin: true, clsLogin: "spinner-border spinner-border-sm text-light" });
        let data = new FormData(e.target);
        data.append("UserType", "Admin");
        data.append("Domain", window.location.hostname);
        fetch(host + "login", {
          method: 'POST',
          body: data,
        }).then(response => response.text()).then(res => {
          if (isJson(res)) {
            let hasil = JSON.parse(res);
            if (hasil.status === "sukses") {
              localStorage.setItem("TokenAdmin", hasil.token);
              window.location.reload();
            } else {
              this.setState({ btnLogin: false, clsLogin: "" });
              pesan("Login Gagal", hasil.pesan, "primary");
            }
          } else {
            console.log(res);
            pesan("Login Gagal", "Terjadi kesalahan", "primary");
          }
        }).catch((error) => {
          this.setState({ btnLogin: false, clsLogin: "" });
          console.log("Error: " + error);
        });
      } else {
        let forms = document.getElementsByClassName('needs-validation');
        let ululu = Array.prototype.filter.call(forms, function (form) {
          form.classList.add('was-validated');
        });
      }
    }

    handleVisible(e) {
      let cls = e.target;
      let edtPwd = document.getElementById('edtPwd');
      if (edtPwd.type == "password") {
        cls.classList.remove("fa-eye-slash");
        cls.classList.add("fa-eye");
        edtPwd.type = "text";
      } else {
        cls.classList.add("fa-eye-slash");
        cls.classList.remove("fa-eye");
        edtPwd.type = "password";
      }
    }

    render() {
      return (
        <section>
          <div className="row">
            <div className="col-md-8">
              <div className="div-img-top">
                <img src={LogoPanjang} className="logo" alt="Logo" />
              </div>
              <div className="card-form">
                <form id="formLogin" className="needs-validation" onSubmit={(e) => this.handleLogin(e)} noValidate>
                  <h4>Login</h4>
                  <h6>Selamat datang kembali di MA Darul Ulum Waru</h6>
                  <div className="form-group">
                    <label>Email</label>
                    <input type="email" name="Email" className="form-control form-control-lg" id="edtEmail" value={this.state.Email} onChange={(e) => this.setState({ Email: e.target.value })} required />
                    <div className="invalid-feedback">Silahkan masukan alamat email</div>
                  </div>
                  <div className="form-group">
                    <label>Password</label>
                    <div className="input-group">
                      <input type="password" name="Password" className="form-control form-control-lg" id="edtPwd" value={this.state.Pwd} onChange={(e) => this.setState({ Pwd: e.target.value })} required />
                      <div className="invalid-feedback">Silahkan masukan password</div>
                      <i className="fas fa-eye-slash btn-opsi" id="btnVisibility" onClick={(e) => this.handleVisible(e)}></i>
                    </div>
                  </div>
                  <div className="d-flex justify-content-end" style={{ marginBottom: "24px" }}>
                    <span className="btn-link" style={{ fontWeight: "bold", cursor: "pointer" }} data-bs-toggle="modal" data-bs-target="#modal">Lupa Password?</span>
                  </div>
                  <button className=" btn btn-lg w-100 btn-login" id="btnLogin">
                    <i id="lblLoading" className={this.state.clsLogin}></i> Login
                  </button>
                  <div className="alert alert-danger" id="lblNotif" style={{ display: "none", marginTop: "1rem" }} role="alert"></div>
                </form>
              </div>
            </div>
            <div className="col-md-4" style={{ padding: "0px" }}>
              <div className="bg-side">
                <img src={BG2} className="img-side-bottom"></img>
              </div>
            </div>
            <div id='divPesan'></div>
          </div>

          <div className="modal fade" id="modal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
              <div className="modal-content">
                <form id="formForgot" className="needs-validation" noValidate>
                  <div className="modal-header" id="modalHeader">
                    <h5 className="modal-title" id="exampleModalLabel">Lupa Password</h5>
                    <button type="button" className="btn btn-close-modal" data-bs-dismiss="modal" id="dismissModal">
                      <span className="material-icons">close</span>
                    </button>
                  </div>
                  <div className="modal-body">
                    <input type="hidden" name="act" value="request" />
                    <div className="form-group">
                      <label>Alamat Email</label>
                      <input type="email" name="email" className="form-control form-control-lg" id="edtEmailForgot" placeholder="Silahkan masukan alamat email anda" required />
                      <div className="invalid-feedback">Silahkan Masukan Alamat Email</div>
                    </div>
                    <p></p>
                    <div className="alert alert-danger" id="divAlert" style={{ display: "none" }} role="alert"></div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Batal</button>
                    <button type="submit" className="btn btn-login" id="btnForgot"><i id="lblDaftar" disabled></i> Kirim Permintaan</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      )
    }
  }

  if (Token) {
    return <Main />
  } else {
    return <Login />
  }
}

export default App;
