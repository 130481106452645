import React, { Fragment } from 'react';
import { api, submitForm, openModal } from '../Modul';
import RendTables from '../component/RendTable';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            Data: [],
            Field: [],
            Detail: {},
            DataMenu: [],
            ArrAkses: [],
            q: "",
            Akses: true
        };
    }

    async componentDidMount() {
        this.handleMain();
        let ColorTheme = localStorage.getItem("ColorTheme") || "light";
        if (ColorTheme == "dark") {
            let cls = document.getElementsByClassName("table");
            for (let i = 0; i < cls.length; i++) {
                cls[i].classList.add("table-dark")
            }
        }
    }

    async handleMain() {
        let sql = await api("controler/masteradmin_api", { act: "data master admin", q: this.state.q });
        if (sql.status == "sukses") {
            this.setState({ Data: sql.data, Field: sql.TableField });
        } else {
            this.setState({ Akses: false });
        }
    }

    async handleModalForm(Detail = {}) {
        let sql = await api("controler/masteradmin_api", { act: "detail master admin", ID: Detail.ID });
        let Akses = sql.akses.Akses != undefined ? JSON.parse(sql.akses.Akses) : [];
        let Menu = sql.menu;
        for (let dd of Akses) {
            for (let ii in Menu) if (dd.ID == Menu[ii].ID) Menu[ii].checked = true;
        }
        this.setState({ Detail: Detail, DataMenu: Menu, ArrAkses: Akses });
        openModal("modalFormMenu");
    }

    modalDelete(Detail) {
        this.setState({ Detail: Detail });
        openModal("modalDelete")
    }

    handlePilihMenu(data) {
        let chk = document.getElementById(`chkTable${data.ID}`);
        let Menu = this.state.DataMenu;
        let Akses = [];
        for (let dd in Menu) {
            if (Menu[dd].ID == data.ID) {
                Menu[dd].checked = chk.checked;
            }
            if (Menu[dd].checked == true) Akses.push(Menu[dd]);
        }
        this.setState({ DataMenu: Menu, ArrAkses: Akses });
    }

    render() {
        return (
            <Fragment>
                <div className="main-header d-flex justify-content-start align-items-center gap-2"></div>
                <div className="main-body">
                    <div className="div-content mt-1">
                        {this.state.Akses == true ?
                            <Fragment>
                                <div className="row">
                                    <div className="col-md-8 main-title">Master Admin</div>
                                    <div className="col-md-4 d-flex gap-1">
                                        <div className="input-group gap-2">
                                            <button className='btn btn-sm btn-default' onClick={() => this.handleModalForm()}>Tambah</button>
                                            <input type="search" className="form-control form-control-sm" value={this.state.q} onChange={(e) => this.setState({ q: e.target.value })} placeholder='Cari Admin' />
                                            <button className="btn btn-default" onClick={() => this.handleMain()} ><i className="fas fa-search"></i></button>
                                        </div>
                                    </div>
                                </div>
                                <RendTables tbody={this.state.Data}
                                    thead={this.state.Field}
                                    group={[
                                        { sort: "Group", cap: "Gruop" }
                                    ]}
                                    opt={[
                                        { icon: "fas fa-edit", fn: (e) => this.handleModalForm(e) },
                                        { icon: "fas fa-trash", fn: (e) => this.modalDelete(e) }
                                    ]} />
                            </Fragment> : <img src={require("../accessdenied.jpeg")} width="100%" className='shadow-lg' />
                        }
                    </div>
                </div>

                {/* Untuk menu */}
                <div className="modal fade" id="modalFormMenu" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-xl">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/masteradmin_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="UserID" value="0" />
                                <input type='hidden' name="Akses" value={JSON.stringify(this.state.ArrAkses)} />
                                <input type='hidden' name="act" value={this.state.Detail.ID == undefined ? "tambah master admin" : "edit master admin"} />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">{this.state.Detail.ID == undefined ? "Tambah Admin" : "Edit Admin"}</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-6">
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Nama</label>
                                                        <input type="text" className="form-control" name='Nama' id="edtNama" value={this.state.Detail.Nama} onChange={(e) => {
                                                            let data = this.state.Detail;
                                                            data.Nama = e.target.value;
                                                            this.setState({ Detail: data });
                                                        }} required />
                                                        <div className="invalid-feedback">Silahkan Masukan Nama</div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Email</label>
                                                        <input type="text" className="form-control" name='Email' id="edtEmail" value={this.state.Detail.Email} onChange={(e) => {
                                                            let data = this.state.Detail;
                                                            data.Email = e.target.value;
                                                            this.setState({ Detail: data });
                                                        }} required />
                                                        <div className="invalid-feedback">Silahkan Masukan Email</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Telp</label>
                                                        <input type="text" className="form-control" name='Telp' inputMode='numberic' id="edtTelp" value={this.state.Detail.Telp} onChange={(e) => {
                                                            let data = this.state.Detail;
                                                            data.Telp = e.target.value;
                                                            this.setState({ Detail: data });
                                                        }} required />
                                                        <div className="invalid-feedback">Silahkan Masukan Telp</div>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label>Password</label>
                                                        <input type="password" className="form-control" name='Password' id="edtPassword" value={this.state.Detail.Password} onChange={(e) => {
                                                            let data = this.state.Detail;
                                                            data.Password = e.target.value;
                                                            this.setState({ Detail: data });
                                                        }} required readOnly={this.state.Detail.ID == undefined ? false : true} />
                                                        <div className="invalid-feedback">Silahkan Masukan Password</div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="d-flex gap-2">
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" name='Status' type="checkbox" role="switch" id="chkStatus" checked={this.state.Detail.Status == 1 ? true : false} onChange={(e) => {
                                                        let data = this.state.Detail;
                                                        data.Status = e.target.checked == true ? 1 : 0;
                                                        this.setState({ Detail: data });
                                                    }} />
                                                    <label className="form-check-label" htmlFor="chkStatus">{this.state.Detail.Status == 1 ? "Aktif" : "Tidak Aktif"}</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <h5>Akses</h5>
                                            <RendTables tbody={this.state.DataMenu}
                                                thead={[
                                                    { cap: "", sort: "", type: "opsi" },
                                                    { cap: "Nama", sort: "Nama", type: "str" },
                                                    { cap: "Group", sort: "Group", type: "str" },
                                                    { cap: "Jenis", sort: "Jenis", type: "str" },
                                                    { cap: "Nomor Urut", sort: "IsOrder", type: "str" },
                                                    { cap: "Keterangan", sort: "Keterangan", type: "str" },
                                                    { cap: "Status", sort: "Status", type: "str" },
                                                ]}
                                                group={[
                                                    { sort: "Group", cap: "Gruop" }
                                                ]}
                                                opt={[
                                                    { icon: "checkbox", fn: (e) => this.handlePilihMenu(e, {}) },
                                                ]} />
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-danger" data-bs-dismiss="modal" id='btnTutupModalFormAdmin'>Tutup</button>
                                    <button type="submit" className="btn btn-default"><i className="fas fa-save"></i> Simpan</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="modal fade" id="modalDelete" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <form onSubmit={(e) => submitForm(e, { crud: 'model/masteradmin_crud', fn: () => this.handleMain(), debug: true })} className='needs-validation' noValidate>
                                <input type='hidden' name="ID" value={this.state.Detail.ID} />
                                <input type='hidden' name="act" value="hapus master admin" />
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5">Hapus Admin</h1>
                                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <h5>Apakah anda yakin akan menghapus admin !!</h5>
                                </div>
                                <div className="modal-footer">
                                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" id='btnTutupModalHapusAdmin'>Batal</button>
                                    <button type="submit" className="btn btn-danger"><i className="fas fa-trash"></i> Hapus</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }

}

export default Main;
